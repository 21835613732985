import React, { useEffect, useState, useMemo } from "react";
import { Link as Link2 } from "react-router-dom";
import * as Unicons from "@iconscout/react-unicons";
import Lightbox from "react-18-image-lightbox";
import "../../node_modules/react-18-image-lightbox/style.css";
import "./css/project-card.css";
import "./css/project-filter.css";
import { Link } from "react-router-dom";

// import Project1 from "../assets/images/project-images/100g-nutrition-image3.png";
import Project2 from "../assets/images/project-images/fcblog-image5.png";
import Project3 from "../assets/images/project-images/omni-food-image2.png";
import Project4 from "../assets/images/project-images/rmtdevfc-image2.png";
import Project5 from "../assets/images/project-images/firstclassdetailing-2.png";
import Project6 from "../assets/images/project-images/flex-craft-3.png";
import Project7 from "../assets/images/project-images/forever-classics-1.png";
import Project8 from "../assets/images/project-images/cineplex-promotional-email.png";
import Project9 from "../assets/images/project-images/pizza-transactional-edited-2.PNG";
import Project10 from "../assets/images/project-images/forbes-newsletter-edited.PNG";
// import TestImage from "../assets/images/project-images/100g-nutrition-image3.png";

const projectList = [
  {
    projectName: "First Class Detaling",
    projectLink: "https://firstclassdetailing.fcsolutions.io/",
    projectImage: Project5,
    projectSkills: " Wordpress, CSS, Responsive Design",
    projectTags: ["ALL", "CMS"],
  },
  {
    projectName: "Flex Craft",
    projectLink: "https://fcsolutions.io/",
    projectImage: Project6,
    projectSkills: "Wordpress, CSS, Responsive Design",
    projectTags: ["ALL", "CMS"],
  },
  {
    projectName: "Forever Classics",
    projectLink: "https://f936a2-e3.myshopify.com/",
    projectImage: Project7,
    projectSkills: "Shopify | PASSWORD: viewme",
    projectTags: ["ALL", "CMS"],
  },
  {
    projectName: "Cineplex Promotional Email",
    projectLink: "https://cineplex-promotional-email.netlify.app/",
    projectImage: Project8,
    projectSkills: "HTML, CSS, MJML, Responsive Design",
    projectTags: ["ALL", "EMAIL"],
  },
  {
    projectName: "Pizza Transactional Email",
    projectLink: "https://pizzapizza-transactional-email.netlify.app/",
    projectImage: Project9,
    projectSkills: "HTML, CSS, MJML, Responsive Design",
    projectTags: ["ALL", "EMAIL"],
  },
  {
    projectName: "Forbes Newsletter",
    projectLink: "https://forbes-newsletter.netlify.app/",
    projectImage: Project10,
    projectSkills: "HTML, CSS, MJML, Responsive Design",
    projectTags: ["ALL", "EMAIL"],
  },
  {
    projectName: "",
    projectLink: "",
    projectImage: "",
    projectSkills: "",
    projectTags: ["None"],
  },
];

const images = [
  "/images/works/1.jpg",
  "/images/works/2.jpg",
  "/images/works/3.jpg",
  "/images/works/4.jpg",
  "/images/works/5.jpg",
  "/images/works/6.jpg",
  "/images/works/7.jpg",
  "/images/works/8.jpg",
];

export default function Projects() {
  const [photoIndex, setActiveIndex] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [projectFilter, setProjectFilter] = useState("ALL");

  // const handleCLick = (index) => {
  //   setActiveIndex(index);
  //   setOpen(!isOpen);
  // };

  const handleFilterChange = (filter) => {
    setProjectFilter("None");

    // setProjectFilter(filter);

    setTimeout(() => {
      setProjectFilter(filter);
    }, 100); // 300ms delay for transition effect
  };

  // const filteredList = projectList.filter((project) =>
  //   project.projectTags.includes(projectFilter)
  // );

  // const filteredList = useMemo(() => {
  //   return projectList.filter((project) =>
  //     project.projectTags.includes(projectFilter)
  //   );
  // }, [projectFilter, projectList]);

  return (
    <>
      <section className="relative md:py-24 py-16" id="project">
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-6 md:text-2xl text-xl md:leading-normal leading-normal font-semibold">
              My Work & Projects
            </h3>

            {/* <p className="text-slate-400 max-w-xl mx-auto text-[15px]">
              Obviously I'm a Web Designer. Web Developer with over 7 years of
              experience. Experienced with all stages of the development.
            </p> */}

            {/* FILTERS */}

            <ul className="project-filters">
              <li
                className={`project-filter ${
                  projectFilter === "ALL" ? "active" : ""
                }`}
                onClick={() => handleFilterChange("ALL")}
              >
                All
              </li>

              <li
                className={`project-filter ${
                  projectFilter === "CMS" ? "active" : ""
                }`}
                onClick={() => handleFilterChange("CMS")}
              >
                Websites
              </li>
              <li
                className={`project-filter ${
                  projectFilter === "EMAIL" ? "active" : ""
                }`}
                onClick={() => handleFilterChange("EMAIL")}
              >
                Emails
              </li>
            </ul>
          </div>

          <div className="project-grid grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 h-0">
            {/* Start of Card */}
            {projectList.map((project, index) => (
              <Link2
                to={project.projectLink}
                target="_blank"
                key={`project-${index}`}
                className={
                  project.projectTags.includes(projectFilter)
                    ? "project-card-wrapper visible"
                    : "project-card-wrapper hide"
                }
              >
                <div className="project-card relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800 h-16">
                  <div className="relative">
                    <img
                      src={project.projectImage}
                      alt=""
                      className="h-full w-full object-cover"
                    />
                  </div>

                  <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                  <div className="absolute  bottom-0 opacity-0 group-hover:opacity-100 m-6 transition-all duration-500">
                    <Link2
                      to={project.projectLink}
                      className="text-white hover:text-amber-500 font-semibold transition-all duration-500 block"
                      target="_blank"
                    >
                      View {project.projectName}
                    </Link2>

                    {/* <Link2
                      to="https://github.com/FarazChand/100g-nutrition-website"
                      target="_blank"
                      className="inline-block "
                    >
                      <span className="block text-md hover:text-amber-500 text-slate-400">
                        View on GitHub
                      </span>
                    </Link2> */}
                    {/* <span className="block text-sm text-slate-400 mt-0">
                      PASSWORD: viewme
                    </span> */}

                    <span className="block text-sm text-slate-400 mt-0">
                      {project.projectSkills}
                    </span>
                  </div>

                  <div className="absolute z-10 opacity-0 group-hover:opacity-100 top-1/2 -translate-y-1/2 right-0 left-0 mx-auto text-center transition-all duration-500"></div>
                </div>
              </Link2>
            ))}
          </div>
        </div>
      </section>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setActiveIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setActiveIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </>
  );
}
